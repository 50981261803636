import React from "react"
import "./card.css"
const BlogPostCard = ({ title, imageUrl, description, url }) => (
  <div className="rounded-xl overflow-hidden opacity-70 relative hover:opacity-100">
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="rounded-xl overflow-hidden opacity-70 relative hover:opacity-100 cursor-pointer"
    >
      <img className="relative" alt={title} src={imageUrl}></img>
      <div className="opacity-0 hover:opacity-100 duration-300 absolute inset-0 z-10 text-3xl text-white font-bold p-2">
        {title}
      </div>
      <p className="text-white truncate">
        <span className="tooltip">
          {description}
          <span className="tooltiptext">{description}</span>
        </span>
      </p>
      {/* <p className="text-white truncate">
        <span className="tooltip">
          {description}
          <span className="tooltiptext">{description}</span>
        </span>
      </p> */}
    </a>
  </div>
)

export default BlogPostCard
