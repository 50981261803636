import React from "react"
import BlogPostCard from "./Cards/blogpostCard"
import { BLOG_POSTS } from "../utils/constants"
const FeaturedBlog = () => {
  return (
    <div className="max-w-7xl mx-auto px-8 mt-10">
      <h1 className="text-white font-poppins font-bold text-6xl xxs:text-lg xs:text-xl sm:text-xl lg:text-6xl">
        Unveiling Insights and Innovations
      </h1>
      <p className="text-white text-lg mt-5 opacity-50 w-3/4 xxs:w-full lg:w-3/4">
        Your Source for Cutting-Edge News and Stories in the World of Digital
        Evolution. Dive into a treasure trove of articles, updates, and deep
        dives that illuminate the dynamic landscapes of e-commerce, edutech,
        fintech, and content platforms, empowering you to stay ahead in the
        digital realm
      </p>
      <div className="grid grid-cols-3 mt-10 gap-4 xxs:grid-cols-1 lg:grid-cols-3">
        {BLOG_POSTS.map(post => (
          <BlogPostCard
            title={post.title}
            url={post.url}
            imageUrl={post.imageUrl}
            description={post.description}
          />
        ))}
      </div>
    </div>
  )
}

export default FeaturedBlog
