import React from "react"
import Fade from "react-reveal/Fade"
import Logo from "../../images/logo.svg"
import MigobucksApp from "../../images/migobucks-app.png"

const FeatureSection = () => {
  return (
    <div className="max-w-7xl mx-auto ">
      <div className="mt-10 px-8">
        <h1 className="text-white text-7xl font-bold xxs:text-2xl xs:text-2xl sm:text-3xl lg:text-5xl xl:text-7xl">
          World-Class Services for <br></br>
          Forward-Thinking Businesses
        </h1>
        <h2 className="text-white text-sm mt-5 opacity-50">
          Explore our broad spectrum of data and technology solutions tailored
          to your business needs and goals.
          <br></br>
        </h2>
      </div>
      <Fade bottom cascade>
        <div className="w-full flex flex-row justify-between mt-10 xxs:flex-col xs:flex-col sm:flex-row">
          <div className="rounded-xl w-full h-52 flex flex-col content-center justify-center align-middle text-center m-5 bg-gradient-to-r from-lightblack to-lightblack hover:from-purple hover:to-pink transition duration-500 ease-in-out">
            <h1 className="text-white text-2xl">
              Enterprise and Cloud Architecture
            </h1>
          </div>
          <div className="rounded-xl w-full h-52 flex flex-col content-center justify-center align-middle text-center m-5 bg-gradient-to-r from-lightblack to-lightblack hover:from-purple hover:to-pink transition duration-500 ease-in-out">
            <h1 className="text-white text-2xl">
              Web and Mobile App Modernization
            </h1>
          </div>
          <div className="rounded-xl w-full h-52 flex flex-col content-center justify-center align-middle text-center m-5 bg-gradient-to-r from-lightblack to-lightblack hover:from-purple hover:to-pink transition duration-500 ease-in-out">
            <h1 className="text-white text-2xl">E-Commerce</h1>
          </div>
          <div className="rounded-xl w-full h-52 flex flex-col content-center justify-center align-middle text-center m-5 bg-gradient-to-r from-lightblack to-lightblack hover:from-purple hover:to-pink transition duration-500 ease-in-out">
            <h1 className="text-white text-2xl">
              Project Discovery and Scoping Phase
            </h1>
          </div>
        </div>
      </Fade>
      <Fade bottom cascade>
        <div className="mt-10 px-8">
          <h1 className="text-white text-7xl font-bold xxs:text-2xl xs:text-2xl sm:text-3xl lg:text-5xl xl:text-7xl">
            From idea to <br></br>
            powerful products
          </h1>
          <h2 className="text-white text-sm mt-5 opacity-50">
            The Journey from Concept to Game-Changing Innovations. Discover the
            process of transforming ideas into impactful products that shape the
            future.
            <br></br>
            Tracing the Progress from Its Earliest Days to Modern Breakthroughs,
            Just as a Printer Transformed Typography Centuries Ago
          </h2>
        </div>
      </Fade>
      <Fade bottom cascade>
        <div className="mt-10 flex flex-row justify-space xxs:flex-col xs:flex-col sm:flex-row">
          <div className="w-2/6 bg-gradient-to-r from-pink to-purple p-8 rounded-xl m-5 xxs:w-full xs:w-full sm:w-2/6">
            <h1 className="text-white text-4xl">Stats</h1>
            <div className="mt-5">
              <div>
                <h2 className="text-white opacity-50 text-xl">Year Founded</h2>
                <h1 className="text-white text-sm">2020</h1>
              </div>
              <div className="mt-5">
                <h2 className="text-white opacity-50 text-xl">Total Funding</h2>
                <h1 className="text-white text-sm">$3M</h1>
              </div>
              <div className="mt-5">
                <h2 className="text-white opacity-50 text-xl">Team Members</h2>
                <h1 className="text-white text-sm">70</h1>
              </div>
            </div>
          </div>

          <div className="w-4/6 bg-lightblack p-8 rounded-xl m-5 xxs:w-full xs:w-full sm:w-4/6">
            <h1 className="text-white text-4xl">Our Core Behaviours</h1>
            <p className="mt-10 text-white opacity-50">
              Our Core Behaviors At Migobucks Tech, we are driven by a set of
              core behaviors that define who we are and guide our mission to
              revolutionize the digital landscape. Innovation is at the heart of
              everything we do. We constantly challenge the status quo, pushing
              the boundaries of what's possible in e-commerce, edutech, fintech,
              and content platforms. Our commitment to excellence ensures that
              every solution we deliver is meticulously crafted to meet the
              highest standards. We thrive on collaboration, fostering a dynamic
              environment that encourages creativity and learning. With a strong
              customer-centric approach, we are dedicated to enhancing user
              experiences and creating lasting value. As we continue our
              journey, these core behaviors remain the foundation of our
              success, driving us to make a meaningful impact in the digital
              world.
            </p>
          </div>
        </div>
      </Fade>
      <Fade bottom cascade>
        <div className="mt-10 px-8">
          <h1 className="text-white text-7xl font-bold xxs:text-2xl xs:text-3xl sm:text-5xl lg:text-7xl">
            Our Journey to Simplify People's Everyday
          </h1>
          <h2 className="text-white text-sm mt-5 opacity-50">
            Unlocking opportunities and innovations to reshape lives, we drive
            change for a brighter and more promising future, one step at a time
            <br></br>
          </h2>
        </div>
      </Fade>
      <Fade bottom cascade>
        <div className="mt-10 flex flex-row justify-space xxs:flex-col xs:flex-col sm:flex-row">
          <div className="w-1/3 bg-gradient-to-r from-pink to-purple p-8 rounded-xl m-5 xxs:w-full xs:w-full sm:w-1/3">
            <h1 className="text-2xl" style={{ textAlign: "center" }}>
              <span style={{ color: "#1b75bb" }}>Soko</span>
              <span style={{ color: "#f82e7d" }}>Skills</span>
            </h1>
            <p className="mt-10 text-white opacity-50 text-sm">
              Cultivate an Elite Talent Ecosystem with Unparalleled Brand
              Resonance. Empowering employers in the strategic acquisition and
              transformative development of top-tier candidates from prestigious
              educational bastions, we embark on a journey to sculpt the future
              of work by nurturing excellence from the very source.
            </p>
          </div>

          <div className="w-1/3 bg-lightblack p-8 rounded-xl m-5 xxs:w-full xs:w-full sm:w-1/3">
            <h1 className="text-2xl" style={{ textAlign: "center" }}>
              <span style={{ color: "#1b75bb" }}>Migo</span>
              <span style={{ color: "#f82e7d" }}>Bucks</span>{" "}
              <span className="text-white">App</span>
            </h1>
            <p className="mt-10 text-white opacity-50 text-sm">
              Your Ultimate Brand Experience Platform. Seamlessly connect with
              personal circles, share rewarding brand experiences, and earn
              vouchers and more. Discover exclusive offers, cashback, and stay
              connected with your favorite brands while sharing and showcasing
              your unique personality. Join the Migobucks circle program to
              supercharge your purchasing power with free credits and vouchers.
            </p>
          </div>
          <div className="w-1/3 bg-lightblack p-8 rounded-xl m-5 xxs:w-full xs:w-full sm:w-1/3">
            <h1 className="text-2xl" style={{ textAlign: "center" }}>
              <span style={{ color: "#1b75bb" }}>Migo</span>
              <span style={{ color: "#f82e7d" }}>Bucks</span>{" "}
              <span className="text-white">Buddy</span>
            </h1>
            <p className="mt-10 text-white opacity-50 text-sm">
              Migobucks Buddy is your gateway to effortless earnings and
              exciting rewards. Whether you're a seasoned Migobucks user or new
              to the platform, our mobile and web apps welcome everyone to
              become a Buddy. With Migo-tasks and Micro-jobs, you can unlock a
              world of opportunities to boost your income. But it doesn't stop
              there. We're revolutionizing sales promotion through our
              innovative Buddy and Customer sub-ecosystems. Exclusive brand
              products and vouchers become promotional tasks, visible in our
              Buddy app's Migo Tasks tab. Join us in redefining how you earn and
              engage with brands!
            </p>
          </div>
        </div>
      </Fade>
    </div>
  )
}

export default FeatureSection
