import React from "react"
import Fade from "react-reveal/Fade"

const OurIndustries = () => {
  return (
    <div className="max-w-7xl mx-auto ">
      <Fade bottom cascade>
        <div className="mt-10 px-8">
          <h1 className="text-white text-7xl font-bold xxs:text-2xl xs:text-3xl sm:text-5xl lg:text-7xl">
            Take the Next Step with Migobucks Technologies
          </h1>
          <h2 className="text-white text-sm mt-5 opacity-50">
            Empowering Effortless Work with Teams, Clients, and Partners. Unlock
            the potential of hassle-free collaboration, making your work more
            productive and enjoyable for everyone involved.
            <br></br>
            Unlock the potential of frictionless communication and productivity
            across the board.
          </h2>
        </div>
      </Fade>
      <div className="max-w-7xl mx-auto px-6 mt-10 h-auto mb-10">
        <h1
          style={{ textAlign: "center" }}
          className="text-white text-2xl font-bold xxs:text-2xl xs:text-2xl sm:text-2xl lg:text-6xl"
        >
          Industries We Serve
        </h1>
      </div>
      <Fade bottom>
        <div className="mt-10 flex flex-row justify-space xxs:flex-col xs:flex-col sm:flex-row">
          <div className="w-1/3 bg-gradient-to-r from-pink to-purple p-8 rounded-xl m-5 xxs:w-full xs:w-full sm:w-1/3">
            <h1 className="text-white text-2xl" style={{ textAlign: "center" }}>
              Health Care
            </h1>
            <p className="mt-10 text-white opacity-50 text-sm">
              Your Ultimate Brand Experience Platform. Seamlessly connect with
              personal circles, share rewarding brand experiences, and earn
            </p>
          </div>

          <div className="w-1/3 bg-lightblack p-8 rounded-xl m-5 xxs:w-full xs:w-full sm:w-1/3">
            <h1 className="text-white text-2xl" style={{ textAlign: "center" }}>
              Fintech<br></br>
            </h1>
            <p className="mt-10 text-white opacity-50 text-sm">
              Cultivate an Elite Talent Ecosystem with Unparalleled Brand
              Resonance. Empowering employers in the strategic acquisition and
              transformative development of top-tier candidates from prestigious
              educational bastions, we embark on a journey to sculpt the future
              of work by nurturing excellence from the very source.
            </p>
          </div>
          <div className="w-1/3 bg-lightblack p-8 rounded-xl m-5 xxs:w-full xs:w-full sm:w-1/3">
            <h1 className="text-white text-2xl" style={{ textAlign: "center" }}>
              Sustainability<br></br>
            </h1>
            <p className="mt-10 text-white opacity-50 text-sm">
              Migobucks Buddy is your gateway to effortless earnings and
              exciting rewards. Whether you're a seasoned Migobucks user or new
              to the platform, our mobile and web apps welcome everyone to
            </p>
          </div>
        </div>
      </Fade>
    </div>
  )
}

export default OurIndustries
